import request from "@/utils/request"
import { Lang,Query } from "./type"
import {ids} from "@/api/common/type"

// 分页获取语言设置列表
export function getLangList(params:Query){
	return request({
		url:"/getLangList",
		method:"get",
		params
	})
}
// 添加
export function addLang(data:Lang){
	return request({
		url:"/addLang",
		method:"post",
		data
	})
}

// 修改
export function editLang(data:Lang){
	return request({
		url:"/editLang",
		method:"post",
		data
	})
}

// 删除
export function delLang(data:ids){
	return request({
		url:"/delLang",
		method:"post",
		data
	})
}