// 公用的数据
import { defineStore } from "pinia";

export type Status={
    id:number,
    code:string,
    value:number,
    name:string,
    enName:string
}

export const useCommonStore = defineStore({
    id: "commonStore",
    state: () => (
        {
            statusList:[] as Status[],   //状态数组
            userStatus:[] as Status[],
            menuType:[] as Status[],   //状态数组
            selectList:[] as any,   //状态数组
        }
    ),

    actions:{
        updateStatusList(statusList:Status[]){
            this.$patch({
                statusList
            })
        },
        updateMenuType(menuType:Status[]){
            this.$patch({
                menuType
            })
        },
        updateUserStatus(userStatus:Status[]){
            this.$patch({
                userStatus
            })
        },
        updateSelectList(selectList:any){
            this.$patch({
                selectList
            })
        },
    }
})