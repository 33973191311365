import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';
import { openLoading, closeLoading } from './loading';
import {getCache} from "@/hook/useCache"
// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
});

// 判断本页面是否有未处理的请求
let reqCount = 0;

// 请求拦截器
service.interceptors.request.use(
  (config: any) => {
    (config.headers as any).token = getCache('token') || '';
    if (!document.getElementById('load-main')) {
      reqCount++;
      if (reqCount == 1) {
        openLoading();
      }
    }
    console.log(config,'config')
    let lang1=localStorage.getItem("language");
    if(config.data){
      config.data.language=lang1;
      // 如果时间有值，将时间的值赋值为空，为了让更新的时候时间可以更新
      if(config.data.time){
        config.data.time='';
      }
    }else{
      config.data={};
      config.data.language=lang1;
    }

    if(config.params){
      config.params.language=lang1;
    }else{
      config.params={};
      config.params.language=lang1;
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    reqCount--;
    if (reqCount <= 0) {
      reqCount = 0;
      closeLoading();
    }
    const { code, message } = response.data;
    if (code == '200') {
      return response.data;
    } else {
      // 响应数据为二进制流处理(Excel导出)
      if (response.data instanceof ArrayBuffer) {
        return response;
      }
      ElMessage({
        message: message || '系统出错',
        type: 'error'
      });
      return Promise.reject(new Error(message || 'Error'));
    }
  },
  (error: any) => {
    reqCount--;
    if (reqCount <= 0) {
      reqCount = 0;
      closeLoading();
    }
    if (error.code == 'ERR_NETWORK') {
      ElMessage({
        message: '服务器开小差了~',
        type: 'error'
      });
    }
    return Promise.reject(error.message);
  }
);

// 导出 axios 实例
export default service;
