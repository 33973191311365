import request from "@/utils/request"
import {Login} from "./type"

export function login(data:Login){
    return request({
        url:"/adminLogin",
        method:"post",
        data:{
            ...data,
            account:data.username
        }
    })
}
export function getAuthorMenu(){
    return request({
        url:"/getAuthorMenu",
        method:"get"
    })
}