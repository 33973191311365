// 设置缓存
export function setCache(key:string,value:string){
    let val=localStorage.getItem("local");
    if(val){
        localStorage.setItem(key,value)
    }else{
        sessionStorage.setItem(key,value)
    }
}
// 获取缓存
export function getCache(key:string):string{
    let val=localStorage.getItem("local");
    if(val){
        return localStorage.getItem(key)||''
    }else{
        return sessionStorage.getItem(key)||''
    }
}
// 清除缓存
export function clearCache(){
    localStorage.clear()
    sessionStorage.clear()
}

export function getLang(){
    return localStorage.getItem("language")||'cn';
}