import { ElLoading } from 'element-plus'

let loading: { close: () => void; }
function openLoading (text='加载中...') {
  loading = ElLoading.service({
    lock: true,
    text,
    background: 'rgba(255, 255, 255, 0.5)',
    customClass: 'osloading' // 重点，修改样式类名
  })
}
function closeLoading () {
  loading&&loading.close&&loading.close()
}
export {
  openLoading,
  closeLoading
}