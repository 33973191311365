// 记录用户点击过的菜单路由 和当前路由
import { defineStore } from "pinia";

export type NavRoute={
    path:string,
    name:string,
    title:string,
    icon?:string
}

export const useNavRoute = defineStore({
    id: "navRoute",
    state: () => (
        {
            currentRoute: '',  //当前的路由的名字
            route:[{path:'/home',name:"Home",title:"首页",icon:'shouye'}] as NavRoute[],   //点击过的路由集合
            isExpand:false,
        }
    ),

    actions:{
        updateExpand(isExpand:boolean){
            this.$patch({
                isExpand
            })
        },
        updateRoute(newRoute:NavRoute,currentRoute:string){
            let route=this.route;
            if(!route.some((item:NavRoute)=>{
                return item.name==newRoute.name;
            })){
                route.push(newRoute);
            }
            
            this.$patch({
                currentRoute,
                route
            })
        },
        delRoute(newRoute:NavRoute,currentRoute?:string){
            let route=this.route.filter((item:NavRoute)=>{
                return item.name!=newRoute.name;
            });

            this.$patch({
                currentRoute:currentRoute||this.currentRoute,
                route
            })
        }
    }
})