<template>
    <div class="panel-item">
        <slot />
    </div>
</template>

<script lang="ts" setup>



</script>

<style lang="scss">
.panel-item {
    width: calc(20% - 20px);
    margin: 6px 10px;
    .el-select{
        width:100%;
    }
}
</style>