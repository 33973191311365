import request from "@/utils/request"
import {Status,statusQuery} from "./type"
import {ids} from "@/api/common/type"

export function getZidianList(params:statusQuery){
	return request({
		url:'/getZidianList',
		method:"get",
		params
	})
}
export function addZidian(data:Status){
	return request({
		url:'/addZidian',
		method:"post",
		data
	})
}
export function editZidian(data:Status){
	return request({
		url:'/editZidian',
		method:"post",
		data
	})
}
export function delZidian(data:ids){
	return request({
		url:'/delZidian',
		method:"post",
		data
	})
}