import { defineStore } from "pinia"
import { RouteRecordRaw } from "vue-router"
import { reactive, ref } from "vue"
import router from "@/router"
import { routes } from "@/router";
import { getAuthorMenu } from "@/api/login/index"
import { Menu } from "@/api/menu/type"
import { defaultRoute } from "@/router/default";
// 根据登录用户获取权限菜单
export const Layout = () => import('@/layout/index.vue');
export const usePermissionStore = defineStore('permission', () => {
	// 存放左侧菜单的数组
	let asideRoutes = reactive<RouteRecordRaw[]>([]);
	// 存放左侧菜单栏数组的一维数组
	let yiweiRoute = reactive<any[]>([]);

	// 存放按钮权限的数组
	let buttonPermission = reactive<string[]>([]);
	// 获取全线路有和按钮
	async function getRoute() {
		let sessionRoute = sessionStorage.getItem("route");
		let sessionButton = sessionStorage.getItem("button") as string;
		let result: RouteRecordRaw[] = [];
		// 如果缓存不存在去请求接口
		if (sessionRoute) {
			result = await JSON.parse(sessionRoute);
			let button = await JSON.parse(sessionButton);
			button.map((item: any) => {
				buttonPermission.push(item);
				return item
			})
		} else {
			let res: any = await getAuthorMenu();
			result = res.data.menu;
			console.log(result,'result',res.button)
			res.data.button.map((item: any) => {
				buttonPermission.push(item.code);
				return item.code
			})
			sessionStorage.setItem("route", JSON.stringify(result));
			sessionStorage.setItem("button", JSON.stringify(buttonPermission));
		}

		let routeArr = sj(result, 'id', 'parentid', 0)

		let route = setRoute(routeArr);
		// 将从后台查出来的和 前端默认都有的重新组合
		let newRoute = [...defaultRoute, ...route];
		// 对组合好的数据进行重新排序  sort 小->大
		newRoute = newRoute.sort(sortBy('sort'))

		let map = new Map();
		for (let item of jw(newRoute, '')) {
			if (!map.has(item.name)) {
				map.set(item.name, item);
			}
		}

		[...map.values()].forEach((item: any) => {
			yiweiRoute.push(item)
		})
		yiweiRoute=yiweiRoute.map((item:any)=>{
			item.icon=getSvg(yiweiRoute,item.name);
			return item
		})
		console.log(yiweiRoute,'yiweiRoute')
		sj(yiweiRoute, 'name', 'pname', '').forEach((item: any) => {
			asideRoutes.push(item)
			routes[0]!.children!.push(item);
		})

		// 最后添加404 防止 刷新之后出现 404
		routes.push({
			path: '/:catchAll(.*)',
			redirect: '/404'
		})
		router.addRoute(routes[0]);
		router.addRoute(routes[routes.length - 1]);
	}
	function getSvg(arr:any[],name:string):string{
		let svg='';
		let index = arr.findIndex((item:any) => {
			return item.name == name;
		})
		if(arr[index]&&arr[index].meta&&arr[index].meta.icon){
			svg=arr[index].meta.icon;
		}else if(arr[index].pname){
			return getSvg(arr,arr[index].pname);
		}
		return svg;
	}
	// 将接口返回来的 一维数据 按照 id和parentid 的关系 重新组合
	let sj = (arr: RouteRecordRaw[], key2: string, pkey2: string, value: number | string) => {
		let map = new Map();
		arr.forEach((item: RouteRecordRaw) => {
			item.children = [];
			map.set(item[key2], item);
		})
		let arr0 = arr.filter((item: RouteRecordRaw) => {
			return item[pkey2] == value;
		})
		for (let i = 0; i < arr.length; i++) {
			if (arr[i][pkey2] == value) {
				continue;
			}

			let parent = map.get(arr[i][pkey2]);
			if(parent&&parent.children)
				parent.children.push(arr[i]);
		}
		return arr0;
	}

	// 将多维数组降为一维数组
	let jw = (arr: any, pname: string) => {
		return arr.reduce((current: any, now: any) => {
			if (now.children) {
				let { children, ...now1 } = now;
				current = current.concat({ ...now1, title: now.meta.title, name: now.name, pname });
				return current.concat(jw(now.children, now.name));
			} else {
				let { children, ...now1 } = now;
				return current.concat({ ...now1, title: now.meta.title, name: now.name, pname })
			}
		}, [])
	}
	// 通过字段排序  小->大
	let sortBy = (field: string) => {
		return (x: any, y: any) => {
			return x[field] - y[field]
		}
	}
	// 将后台返回来的路由组件引入
	function setRoute(res: RouteRecordRaw[], path?: string) {
		let route: RouteRecordRaw[] = [];
		res.forEach((item: any) => {
			let p = '';
			// 组装子路由跳转的路径
			if (path) {
				p = path + '/' + item.path;
			} else {
				p = '/' + item.path;
			}
			// 组装组件信息
			let com: RouteRecordRaw = {
				path: p,
				name: item.name,
				sort: item.sort,
				type:item.type,
				url:item.url,
				component: () => import("@/views/" + item.file),
				meta: {
					title: item.title,
					icon: item.icon
				},
				children: []
			}
			// 如果有子路由，进行递归循环
			if (item.children) {
				com.children = setRoute(item.children, p)
			}
			route.push(com)
		})
		return route;
	}

	return {
		asideRoutes,
		buttonPermission,
		yiweiRoute,
		getRoute
	}
})