import { RouteRecordRaw } from 'vue-router'

export const defaultRoute: RouteRecordRaw[] = [{
    path: '/home',
    component: () => import("@/views/home/index.vue"),
    name: "Home",
    sort: 0,
    meta: {
        title: '后台首页',
        icon: 'shouye',
    },
},
{
    path: '/product',
    component: () => import("@/views/routeview/index.vue"),
    name: "Product",
    sort: 2,
    meta: {
        title: '产品管理',
        icon: 'excel',
    },
    redirect:'/product/list',
    children: [
        {
            path: '/product/type',
            component: () => import("@/views/product/type/index.vue"),
            name: "ProductType",
            sort: 1,
            meta: {
                title: '产品分类',
                icon: '',
            },
        },
        {
            path: '/product/list',
            component: () => import("@/views/product/list/index.vue"),
            name: "ProductList",
            sort: 2,
            meta: {
                title: '产品列表',
                icon: '',
            },
        },
        {
            path: '/product/detailList',
            component: () => import("@/views/product/detail/index.vue"),
            name: "ProductDetail",
            sort: 3,
            meta: {
                title: '产品详情',
                icon: '',
            },
        },
        {
            path: '/product/city',
            component: () => import("@/views/product/city/index.vue"),
            name: "CityList",
            sort: 4,
            meta: {
                title: '城市列表',
                icon: '',
            },
        },
        {
            path: '/product/city1',
            component: () => import("@/views/product/city1/index.vue"),
            name: "Destination",
            sort: 5,
            meta: {
                title: '目的地列表',
                icon: '',
            },
        },
        {
            path: '/product/carpoint',
            component: () => import("@/views/product/carpoint/index.vue"),
            name: "Carpoint",
            sort: 6,
            meta: {
                title: '参离团列表',
                icon: '',
            },
        },
        {
            path: '/product/travel',
            component: () => import("@/views/product/travel/index.vue"),
            name: "Travel",
            sort: 7,
            meta: {
                title: '行程基本信息',
                icon: '',
            },
        },
        {
            path: '/product/travelcontent',
            component: () => import("@/views/product/travelcontent/index.vue"),
            name: "TravelContent",
            sort: 8,
            meta: {
                title: '具体行程内容',
                icon: '',
            },
        },
        {
            path: '/product/jingdian',
            component: () => import("@/views/product/jingdian/index.vue"),
            name: "Jingdian",
            sort: 9,
            meta: {
                title: '景点列表',
                icon: '',
            },
        },
        {
            path: '/product/hotel',
            component: () => import("@/views/product/hotel/index.vue"),
            name: "hotelList",
            sort: 10,
            meta: {
                title: '酒店信息',
                icon: '',
            },
        },
        {
            path: '/product/shop',
            component: () => import("@/views/product/shop/index.vue"),
            name: "shopList",
            sort: 11,
            meta: {
                title: '购物点列表',
                icon: '',
            },
        },
        {
            path: '/product/notice',
            component: () => import("@/views/product/notice/index.vue"),
            name: "Notice",
            sort: 12,
            meta: {
                title: '注意事项',
                icon: '',
            },
        },
        {
            path: '/product/cost',
            component: () => import("@/views/product/cost/index.vue"),
            name: "costList",
            sort: 13,
            meta: {
                title: '自费项目',
                icon: '',
            },
        },
        
        {
            path: '/product/date',
            component: () => import("@/views/product/date/index.vue"),
            name: "dateList",
            sort: 13,
            meta: {
                title: '团期设置',
                icon: '',
            },
        },
        
        {
            path: '/product/promote',
            component: () => import("@/views/product/promote/index.vue"),
            name: "promoteList",
            sort: 13,
            meta: {
                title: '增值服务',
                icon: '',
            },
        },
        
        {
            path: '/product/price',
            component: () => import("@/views/product/price/index.vue"),
            name: "priceList",
            sort: 13,
            meta: {
                title: '价格设置',
                icon: '',
            },
        }
    ]
},
{
    path: '/gonglue',
    component: () => import("@/views/routeview/index.vue"),
    name: "Gonglue",
    sort: 2,
    meta: {
        title: '旅游攻略',
        icon: 'lvxing',
    },
    redirect:'/gonglue/list',
    children: [
        {
            path: '/gonglue/type',
            component: () => import("@/views/gonglue/type/index.vue"),
            name: "GonglueType",
            sort: 2,
            meta: {
                title: '攻略分类',
                icon: '',
            },
        },
        {
            path: '/gonglue/city',
            component: () => import("@/views/gonglue/city/index.vue"),
            name: "GonglueCity",
            sort: 2,
            meta: {
                title: '地区列表',
                icon: '',
            },
        },
        {
            path: '/gonglue/list',
            component: () => import("@/views/gonglue/list/index.vue"),
            name: "GonglueList",
            sort: 2,
            meta: {
                title: '攻略列表',
                icon: '',
            },
        },
        {
            path: '/gonglue/xianlu',
            component: () => import("@/views/gonglue/xianlu/index.vue"),
            name: "XianluList",
            sort: 2,
            meta: {
                title: '推荐线路',
                icon: '',
            },
        },
        {
            path: '/gonglue/user',
            component: () => import("@/views/gonglue/user/index.vue"),
            name: "GonglueUser",
            sort: 2,
            meta: {
                title: '用户列表',
                icon: '',
            },
        },
        {
            path: '/gonglue/share',
            component: () => import("@/views/gonglue/share/index.vue"),
            name: "GonglueShare",
            sort: 2,
            meta: {
                title: '分享设置',
                icon: '',
            },
        },
    ]
},
{
    path: '/caiye',
    component: () => import("@/views/routeview/index.vue"),
    name: "Caiye",
    sort: 2,
    meta: {
        title: '产品彩页',
        icon: 'lvxing',
    },
    redirect:'/caiye/desc',
    children: [
        {
            path: '/caiye/desc',
            component: () => import("@/views/caiye/desc/index.vue"),
            name: "CaiyeDesc",
            sort: 2,
            meta: {
                title: '彩页描述',
                icon: '',
            },
        },
        {
            path: '/caiye/type',
            component: () => import("@/views/caiye/type/index.vue"),
            name: "CaiyeType",
            sort: 2,
            meta: {
                title: '彩页分类',
                icon: '',
            },
        },
        {
            path: '/caiye/list',
            component: () => import("@/views/caiye/list/index.vue"),
            name: "CaiyeList",
            sort: 2,
            meta: {
                title: '彩页列表',
                icon: '',
            },
        }
    ]
},
{
    path: '/lang',
    component: () => import("@/views/lang/index.vue"),
    name: "Lang",
    sort: 4,
    meta: {
        title: '语言设置',
        icon: 'regexp',
    },
},
{
    path: '/test',
    component: () => import("@/views/test/index.vue"),
    name: "Test",
    sort: 4,
    meta: {
        title: '代码测试',
        icon: 'regexp',
    },
},
{
    path: '/404',
    component: () => import("@/views/error/404.vue"),
    name: "404",
    sort: 100,
    meta: {
        title: '页面不见了~',
        icon: 'shouye',
    },
}
]