import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from '@/store'
import ElementPlus from 'element-plus'
import lang from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/dist/index.css'

import SvgIcon from '@/components/SvgIcon/index.vue' // svg组件
import svgIcons from "@/components/SvgIcon/index.js"
import SelectPanel from '@/components/SelectPanel/Panel.vue'
import SelectPanelItem from '@/components/SelectPanel/PanelItem.vue'
import {
  usePermissionStore
} from "@/store/modules/permission"
import {i18n, $t} from './locales'

const app = createApp(App)
app.provide('$t', $t)
// 多语言 国际化
app.use(i18n)
// 注册组件
app.component('svg-icon', SvgIcon)
app.component('select-panel', SelectPanel)
app.component('select-panel-item', SelectPanelItem)
app.use(store)
app.config.globalProperties.$svgIcons=svgIcons
app.config.globalProperties.$uploadPath=process.env.VUE_APP_BASE_API+'fileUpload'
console.log('路径',process.env.VUE_APP_BASE_API)
// 自定义按钮权限的指令
let {
  buttonPermission
} = usePermissionStore();
// 按钮权限指令定义
app.directive('hasPermission', {
  mounted(el, binding, vnode) {
    const { value } = binding;
    if (value && value.length) {
      let f = buttonPermission.some(p => p == value[0]);
      if (!f) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  }
})

let langObj={};
if(localStorage.getItem("language")=='en'){
  // langObj['locale']=lang
}else{
  langObj['locale']=lang
}
app.use(router).use(ElementPlus, langObj).mount('#app')

