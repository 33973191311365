import { createRouter, createWebHistory, RouteRecordRaw,createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { usePermissionStore } from "@/store/modules/permission"
import { useNavRoute,NavRoute } from '@/store/modules/route';

import { getCache } from "@/hook/useCache"
import {defaultRoute} from "./default"
export const Layout = () => import('@/layout/index.vue');
export const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		component: Layout,
		redirect: '/home',
		children: [
			...defaultRoute
		]
	},
	{
		path: '/login',
		component: () => import("@/views/login/index.vue"),
		name: "Login",
		meta: {
			title: '登录'
		}
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})
router.beforeEach(async (to, from, next) => {
	if (!document.getElementById('load-main')) {
		// 开启动画
		NProgress.start()
	}
	
	if (getCache("token")) {
		let permissionStore = usePermissionStore();
		if (!permissionStore.asideRoutes.length) {
			await permissionStore.getRoute();
			next({ ...to, replace: true });
		} else {
			let myRoute=useNavRoute();
			let robj={path:to.fullPath,name:to.name,title:to.meta.title} as NavRoute;
			let name:any=to.name;
			myRoute.updateRoute(robj,name);
			next();
		}
	} else if (to.name == 'Login') {
		next();
	} else {
		next('login');
	}
})
router.afterEach(() => {
	if (document.getElementById('load-main')) {
		// 关闭加载动画
		document.getElementById('load-main')!.remove();
	}else{
		// 关闭
		NProgress.done()
	}
})
export default router
