import { createI18n } from 'vue-i18n'
import axios from "axios"
import EN from './en'
import ZH from './zh'
import {getLangList} from "@/api/lang"
const messages = {
  en: {},
  zh: {}
}

const langCode = localStorage.getItem("language") || 'cn'
export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: langCode, //去getCurrentLanguage函数找有没有设置的语言,默认选择zh
  messages: messages
})

export function setI18nLanguage(lang: any) {
  i18n.global.locale = lang;
  return lang;
}

export function loadLanguageAsync(lang: any) {
  return getLangList({
    lang,
    size:1000,
    page:1,
    parentid:0
  }).then(res => {
    let list= res.data.records;
    
    let obj={};
    let iList=digui(list,obj);
    
    i18n.global.setLocaleMessage(lang, obj);
    return setI18nLanguage(lang);   // 返回并且设置
  }).catch(err => {

  })
}

function digui(list:any,obj:any,code=''){
  if(code){
    obj[code]={}
  }
  list.forEach((item:any,index:number)=>{
    let content = item.content;
    if(localStorage.getItem("language")=='en'){
      content = item.enContent;
    }
    if(item.children.length){
      digui(item.children,obj,item.code);
    }else if(code){
      obj[code][item.code]=content;
    }else{
      obj[item.code]=content;
    }
  })
  return obj;
}

loadLanguageAsync(langCode);
export function $t(args: any) {
  return i18n.global.t(args)
}