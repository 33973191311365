const req = require.context('@/icons/svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys()
const requireAll1 = (requireContext) => requireContext.keys().map(requireContext);

const re = /\.\/(.*)\.svg/
requireAll1(req);
const svgIcons = requireAll(req).map(i => {
  return i.match(re)[1]
})

export default svgIcons
